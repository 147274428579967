<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="documenti" url="/sportingclub/document/index" :is_dialog="true"
            base_url="/sportingclub/document" base_path="/"
            :fixed_filters="[{ name: 'idsportingclub', filter: idsportingclub }]" custom_class="mx-0 px-0"
            v-on:close-modal="closeModalDocumenti">
            <template v-slot:title>
                <h2 class="card-title">
                    <span class="card-label font-weight-bolder text-dark font-size-h3">Documenti Sodalizio</span>
                </h2>
            </template>

            <template v-slot:item-actions="slotProps">
                <td v-if="
                    typeof slotProps.values.item.actions !== 'undefined' &&
                    slotProps.values.item.actions.length
                " class="text-nowrap pr-0 text-center">
                    <a href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-info mr-3'"
                        title="Carica Documento" alt="Carica Documento"
                        @click.prevent="caricaDocumento(slotProps.values.item.id)">
                        <i class="fas fa-upload"></i>
                    </a>

                    <template v-for="(action, j) in slotProps.values.item.actions">
                        <a v-if="
                            typeof action.is_external === 'undefined' || !action.is_external
                        " href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' +
                action.class +
                (j < slotProps.values.item.actions.length - 1 ? ' mr-3' : '')
                " :title="action.label" :alt="action.label" @click.prevent="
                slotProps.values.doItemAction(action, slotProps.values.item.id)
                ">
                            <i :class="action.icon"></i>
                        </a>

                        <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' +
                            action.class +
                            (j < slotProps.values.item.actions.length - 1 ? ' mr-3' : '')
                            " :title="action.label" :alt="action.label">
                            <i :class="action.icon"></i>
                        </a>
                    </template>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
import SmartTable from "@/view/components/SmartTable.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
    props: ["idsportingclub", "annualityid"],

    data() {
        return {
            loading: false,
            tabIndex: 0,
        };
    },

    components: {
        SmartTable,
    },

    created() { },

    computed: {},

    mounted() {
        this.$watch(
            () => {
                let fields = this.$refs.documenti.componentFields;

                if (fields.length) {
                    let field = this.findField(
                        this.$refs.documenti.$refs.modalcomponent,
                        "idattachmenttype"
                    );
                    let value = this.getFieldValue(field);

                    if (value && value.id) {
                        return value.id;
                    }

                    return null;
                } else return null;
            },
            (id) => {
                let fieldAnno = this.findField(
                    this.$refs.documenti.$refs.modalcomponent,
                    "idannuality"
                );
                let fieldAnnoValue = null;
                if (fieldAnno && fieldAnno.value)
                    fieldAnnoValue = JSON.parse(JSON.stringify(fieldAnno.value));

                this.setFieldVisible(
                    this.$refs.documenti.$refs.modalcomponent,
                    "idannuality",
                    false
                );

                this.setFieldVisible(
                    this.$refs.documenti.$refs.modalcomponent,
                    "documentemissiondate",
                    false
                );
                this.setFieldAttribute(
                    this.$refs.documenti.$refs.modalcomponent,
                    "documentemissiondate",
                    "label",
                    "Data"
                );

                if (id) {
                    if (id === 2911) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data creazione Statuto"
                        );
                    }

                    if (id === 2912) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Atto Costitutivo"
                        );
                    }

                    if (id === 2919) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "idannuality",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "idannuality",
                            "type",
                            "integer"
                        );

                        let vm = this;
                        if (fieldAnno && fieldAnno.filter_options === null) {
                            vm.loadOptions(
                                this.$refs.documenti.$refs.modalcomponent,
                                "annualities",
                                "idannualities",
                                0,
                                "idannuality",
                                function (options) {
                                    options.unshift({
                                        id: 0,
                                        solarsannualitydisplay: "Seleziona un anno",
                                    });
                                },
                                function (options) {
                                    if (fieldAnno) {
                                        vm.$set(fieldAnno, "filter_options", options);

                                        if (fieldAnnoValue !== null) {
                                            let option = options.find(function (item) {
                                                return item.id === fieldAnnoValue;
                                            });
                                            fieldAnno.value = JSON.parse(JSON.stringify(option));
                                        }
                                    }
                                }
                            );
                        }
                    }

                    if (id === 2920) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale Assemblea Consiglio"
                        );
                    }

                    if (id === 2921) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale modifica Statuto"
                        );
                    }

                    if (id === 2935) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale modifica Legale Rappresentante"
                        );
                    }

                    if (id === 2937) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Modifiche e aggiornamenti - Cambio Natura Giuridica"
                        );
                    }

                    if (id === 2938) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Verbale di modifica sede legale"
                        );
                    }

                    if (id === 2939) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Visura Camerale"
                        );
                    }

                    if (id === 2940) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Nomina Responsabile contro Abusi, Violenze e Discriminazioni"
                        );
                    }

                    if (id === 2941) {
                        this.setFieldVisible(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            true
                        );
                        this.setFieldAttribute(
                            this.$refs.documenti.$refs.modalcomponent,
                            "documentemissiondate",
                            "label",
                            "Data Nomina Responsabile contro Abusi, Violenze e Discriminazioni"
                        );
                    }
                }
            }
        );

        this.$watch(
            () => {
                let fields = this.$refs.documenti.componentFields;

                if (fields.length) {
                    if (fields.length) {
                        if (
                            (this.$refs.documenti.$refs.modalcomponent.form_type &&
                                this.$refs.documenti.$refs.modalcomponent.form_type ===
                                "edit" &&
                                this.$refs.documenti.componentValuesloaded) ||
                            (this.$refs.documenti.$refs.modalcomponent.form_type &&
                                this.$refs.documenti.$refs.modalcomponent.form_type === "new")
                        ) {
                            let field = this.findField(
                                this.$refs.documenti.$refs.modalcomponent,
                                "attachment"
                            );
                            let value = this.getFieldValue(field);

                            if (value) {
                                return value;
                            }

                            return {
                                path: "",
                            };
                        }
                    }

                    return null;
                } else return null;
            },
            (newAttachment, oldAttachment) => {
                if (typeof newAttachment === "undefined") return;
                if (typeof oldAttachment === "undefined") return;

                if (newAttachment === null) return;
                if (oldAttachment === null) return;

                if (newAttachment.path !== oldAttachment.path) {
                    this.setFieldAttribute(
                        this.$refs.documenti.$refs.modalcomponent,
                        "documentemissiondate",
                        "value",
                        ""
                    );
                }
            }
        );
    },

    methods: {
        getApiUrl() {
            return ApiService.getApiUrl();
        },

        closeModalDocumenti() {
            this.$refs.documenti.refresh();
        },

        findField(obj, name) {
            if (
                typeof obj !== "undefined" &&
                obj &&
                typeof obj.fields !== "undefined" &&
                obj.fields
            ) {
                return obj.fields.find((item) => item.name === name);
            }

            return null;
        },

        getFieldValue(field) {
            if (field) {
                let value = typeof field.value !== "undefined" ? field.value : null;

                if (value !== null) {
                    return JSON.parse(JSON.stringify(value));
                }
            }

            return null;
        },

        setFieldVisible(obj, name, visible) {
            if (typeof obj.fields !== "undefined") {
                let fieldIndex = obj.fields.findIndex((item) => item.name === name);
                if (fieldIndex) {
                    if (
                        typeof obj.fields !== "undefined" &&
                        typeof obj.fields[fieldIndex] !== "undefined"
                    )
                        this.$set(obj.fields[fieldIndex], "is_visible_for_store", visible);
                    if (
                        typeof obj.fields !== "undefined" &&
                        typeof obj.fields[fieldIndex] !== "undefined"
                    )
                        this.$set(obj.fields[fieldIndex], "is_visible_for_update", visible);
                }
            }
        },

        setFieldAttribute(obj, name, attribute, value) {
            let fieldIndex = obj.fields.findIndex((item) => item.name === name);
            if (fieldIndex) {
                this.$set(obj.fields[fieldIndex], attribute, value);
            }
        },

        loadOptions(
            obj,
            type,
            parameterName,
            parameterValue,
            fieldName,
            callbackEmptyOption,
            callbackOptions = null
        ) {
            let url =
                "/sportingclub/load-options?type=" +
                type +
                "&" +
                parameterName +
                "=" +
                parameterValue;

            ApiService.query(url)
                .then((response) => {
                    let options = response.data;

                    if (typeof options === "undefined") options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    } else {
                        this.setFieldAttribute(obj, fieldName, "filter_options", options);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    let options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    } else {
                        this.setFieldAttribute(obj, fieldName, "filter_options", options);
                    }
                });
        },

        caricaDocumento(id) {
            Swal.fire({
                title: "Conferma Invio Documento",
                text: "Sei sicuro di voler inviare il Documento?",
                icon: "success",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: "Conferma",
                cancelButtonText: "Annulla",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.loading = true;

                    let url = "/rsmonitoring/send-document";
                    let data = { iddocument: id, idannuality: this.annualityid };

                    ApiService.post(url, data)
                        .then((response) => {
                            if (response.data.status === "OK") {
                                this.$bvToast.toast("Invio messo correttamente in coda", {
                                    title: "Invio messo correttamente in coda",
                                    variant: "success",
                                    solid: true,
                                });

                                this.$emit("update-monitoring");
                            }

                            if (response.data.status === "KO") {
                                this.$bvToast.toast(
                                    "Si sono verificati degli errori nella messa in coda dell'invio.",
                                    {
                                        title: "Errore nella messa in coda dell'invio",
                                        variant: "danger",
                                        solid: true,
                                    }
                                );
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });

                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style>
.border-bluenavy .card-header {
    border-bottom: 1px solid #143d67;
}
</style>
