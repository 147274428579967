<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="consiglio" url="/sportingclub/board/index" :is_dialog="true"
                    base_url="/sportingclub/board" base_path="/sportingclub/board" :fixed_filters="[{ name: 'idsportingclub', filter: idsportingclub }, { name: 'isdirector', filter: '1' }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalOrganigramma">
            <template v-slot:title>
                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Consiglio Direttivo</span></h2>
            </template>

            <template v-slot:item-actions="slotProps">
                <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                    <a href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-info mr-3'" title="Carica Membro" alt="Carica Membro" @click.prevent="caricaMembro(slotProps.values.item.id)">
                        <i class="fas fa-upload"></i>
                    </a>

                    <template v-for="(action, j) in slotProps.values.item.actions">
                        <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item.id)">
                            <i :class="action.icon"></i>
                        </a>

                        <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                            <i :class="action.icon"></i>
                        </a>
                    </template>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import ApiService from "@/core/services/api.service";
 import Swal from 'sweetalert2';

 export default {
     props: ['idsportingclub', 'annualityid'],

     data() {
         return {
             loading: false,
             tabIndex: 0,
         };
     },

     components: {
         SmartTable,
     },

     created() {
     },

     computed: {
     },

     mounted() {
         this.$watch(
             () => {
                 let fields = this.$refs.consiglio.componentFields;

                 if (fields.length)
                 {
                     let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idroletype');
                     let value = this.getFieldValue(field);

                     if (value && value.id) {
                         return value.id;
                     }

                     return null;
                 }
                 else return null;
             },
             (id) => {
                 if (id) {
                     if (id === 2101 || id === 2115) {
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idregion', true);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate', true);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idcity', true);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetaddress', true);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetnumber', true);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'zipcode', true);
                     }
                     else {
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idregion', false);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate', false);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'idcity', false);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetaddress', false);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'streetnumber', false);
                         this.setFieldVisible(this.$refs.consiglio.$refs.modalcomponent, 'zipcode', false);
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.consiglio.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.consiglio.$refs.modalcomponent.form_type === 'edit' || this.$refs.consiglio.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.consiglio.componentValuesloaded === null || this.$refs.consiglio.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate');
                         if (fieldProvstate && typeof fieldProvstate.value !== "undefined")
                         {
                             fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                         }
                         else
                         {
                             fieldProvstateValue = {};
                         }
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions(this.$refs.consiglio.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.consiglio.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.consiglio.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.consiglio.$refs.modalcomponent.form_type === 'edit' || this.$refs.consiglio.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.consiglio.componentValuesloaded === null || this.$refs.consiglio.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.consiglio.$refs.modalcomponent, 'idcity');
                         if (fieldCity && typeof fieldCity.value !== "undefined")
                         {
                             fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                         }
                         else
                         {
                             fieldCityValue = {};
                         }
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions(this.$refs.consiglio.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.consiglio.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );
     },

     methods: {
         getApiUrl() {
             return ApiService.getApiUrl();
         },

         closeModalOrganigramma() {
             this.$refs.consiglio.refresh();
         },

         findField(obj, name) {
             if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                 return obj.fields.find(item => item.name === name);
             }

             return null;
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(obj, name, visible) {
             if (typeof obj.fields !== "undefined") {
                 let fieldIndex = obj.fields.findIndex(item => item.name === name);
                 if (fieldIndex) {
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                 }
             }
         },

         setFieldAttribute(obj, name, attribute, value) {
             let fieldIndex = obj.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(obj.fields[fieldIndex], attribute, value);
             }
         },

         loadOptions(obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/sportingclub/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
         },

         caricaMembro(id) {
             Swal.fire({
                 title: 'Conferma Invio Membro',
                 text: "Sei sicuro di voler inviare il Membro?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/rsmonitoring/send-member';
                     let data = { idmember: id, idannuality: this.annualityid };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Invio messo correttamente in coda', {
                                           title: 'Invio messo correttamente in coda',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$emit('update-monitoring');
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                           title: 'Errore nella messa in coda dell\'invio',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },
     }
 };
</script>

<style>
 .border-bluenavy .card-header {
	 border-bottom: 1px solid #143D67;
 }
</style>
