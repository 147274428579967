var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","opacity":"0.50","blur":"blur","spinner-variant":"primary"}},[_c('SmartTable',{ref:"documenti",attrs:{"url":"/sportingclub/document/index","is_dialog":true,"base_url":"/sportingclub/document","base_path":"/","fixed_filters":[{ name: 'idsportingclub', filter: _vm.idsportingclub }],"custom_class":"mx-0 px-0"},on:{"close-modal":_vm.closeModalDocumenti},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"card-title"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark font-size-h3"},[_vm._v("Documenti Sodalizio")])])]},proxy:true},{key:"item-actions",fn:function(slotProps){return [(
                typeof slotProps.values.item.actions !== 'undefined' &&
                slotProps.values.item.actions.length
            )?_c('td',{staticClass:"text-nowrap pr-0 text-center"},[_c('a',{class:'btn btn-icon btn-circle btn-sm btn-info mr-3',attrs:{"href":"javascript:void(0);","title":"Carica Documento","alt":"Carica Documento"},on:{"click":function($event){$event.preventDefault();return _vm.caricaDocumento(slotProps.values.item.id)}}},[_c('i',{staticClass:"fas fa-upload"})]),_vm._l((slotProps.values.item.actions),function(action,j){return [(
                        typeof action.is_external === 'undefined' || !action.is_external
                    )?_c('a',{class:'btn btn-icon btn-circle btn-sm btn-' +
            action.class +
            (j < slotProps.values.item.actions.length - 1 ? ' mr-3' : ''),attrs:{"href":"javascript:void(0);","title":action.label,"alt":action.label},on:{"click":function($event){$event.preventDefault();return slotProps.values.doItemAction(action, slotProps.values.item.id)}}},[_c('i',{class:action.icon})]):_c('a',{class:'btn btn-icon btn-circle btn-sm btn-' +
                        action.class +
                        (j < slotProps.values.item.actions.length - 1 ? ' mr-3' : ''),attrs:{"href":slotProps.values.appendToken(action.url),"target":"_blank","title":action.label,"alt":action.label}},[_c('i',{class:action.icon})])]})],2):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }